import PropTypes from 'prop-types';

const supportedValidatorsShape = {
  date: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.shape({
      message: PropTypes.string,
    }).isRequired,
  ]).isRequired,
  equalsTo: PropTypes.shape({
    to: PropTypes.string.isRequired,
    message: PropTypes.string,
  }).isRequired,
  notEqualsTo: PropTypes.shape({
    to: PropTypes.string.isRequired,
    message: PropTypes.string,
  }).isRequired,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      allowWhitespace: PropTypes.bool,
      validateAgainstFalse: PropTypes.bool,
      message: PropTypes.string,
    }),
  ]).isRequired,
  stringLength: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    message: PropTypes.string,
  }).isRequired,
  maxLength: PropTypes.shape({
    max: PropTypes.number.isRequired,
    message: PropTypes.string,
  }).isRequired,
  regex: PropTypes.shape({
    pattern: PropTypes.string.isRequired,
    message: PropTypes.string,
  }).isRequired,
  readableName: PropTypes.bool.isRequired,
  range: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    message: PropTypes.string,
  }).isRequired,
  minAge: PropTypes.shape({
    minAge: PropTypes.number.isRequired,
    message: PropTypes.string,
  }).isRequired,
  custom: PropTypes.func.isRequired,
  stringLengthOptional: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    message: PropTypes.string,
  }).isRequired,
};

const supportedValidators = Object.keys(supportedValidatorsShape).join(',');

export default function (props, propName, componentName, ...args) {
  const validation = props[propName];
  if (validation == null)
    return;

  const fieldIsNotObjectError = PropTypes.object(props, propName, componentName, ...args);
  if (fieldIsNotObjectError)
    return fieldIsNotObjectError;

  const validationObjectKeys = Object.keys(validation);
  for (const key of validationObjectKeys)
    if (!supportedValidatorsShape[key])
      return new Error(`Validator ${key} is not supported. Use one of supported validators - ${supportedValidators}.`);

  const currentValidatorsShape = validationObjectKeys
    .reduce((shape, validator) => {
      shape[validator] = supportedValidatorsShape[validator];
      return shape;
    }, {});

  return PropTypes.shape(currentValidatorsShape)(props, propName, componentName, ...args);
}