import { memo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { createFieldId, createLabelId } from './helpers';

const FieldLabel = ({ fieldName, children, required, notNative, ...attributes }) => {
  
  const formik = useFormikContext();
  const formName = formik.status.formName;
  const labelId = createLabelId(formName, fieldName);
  const fieldId = createFieldId(formName, fieldName);
  let htmlFor = null, onClick = null;

  if (notNative)
    onClick  = () => {
      const element = document.getElementById(fieldId);
      element && element.focus && element.focus();
    };
  else
    htmlFor = fieldId;

  if (typeof children === 'string' && required)
    children += '*';

  return (
    // Handle onClick to simulate default label behaviour with `for` attribute.
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
    <label id={labelId} htmlFor={htmlFor} onClick={onClick} {...attributes}>
      {children}
    </label>
  );
};

FieldLabel.propTypes = {
  fieldName: PropTypes.string.isRequired,
  children: PropTypes.node,
  required: PropTypes.bool,
  notNative: PropTypes.bool,
};

export default memo(FieldLabel);