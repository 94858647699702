export default context => value => {
  value = value || '';

  const { min, max } = context.params;

  const length = value.length;

  if(length >0 ){
    if ( min > length)
      return context.errorMessage;

    if (max && length > max)
      return context.errorMessage;
  }
  else{
    return;
  }

};