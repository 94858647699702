import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { getIn } from 'formik';
import { notifyDeliveryDateStepChanged } from 'behavior/pages/checkout/actions';
import { onDeliveryDateSelected } from 'behavior/user/actions';

export default function (Primitive) {
  const componentBuilder = ({ field, form, className, ...rest }) => {

    //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
    const dispatch = useDispatch();
    const { name, value } = field;
    const { setFieldValue, setFieldTouched, errors, touched } = form;
    const isValid = !getIn(touched, name) || !getIn(errors, name);
    
    const onChange = useCallback(date => {

      //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
      if (field.name === "deliveryDate") {
        dispatch(notifyDeliveryDateStepChanged(date));
      }

      setFieldTouched(name, true, false);
      setFieldValue(name, date);

      //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
      if (name === "callOffOrder-orderDate") {
        dispatch(onDeliveryDateSelected(date));
      }

    }, [name, setFieldValue, setFieldTouched]);



    return (
      <Primitive
        name={name}
        {...rest}
        value={value}
        onChange={onChange}
        isValid={isValid}
      />
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  const DateField = ({ fieldName, fieldTitle, validation, initialValue, minDate, maxDate, ...props }) => {
    const dateValidation = {
      ...validation,
      date: true,
      datesRange: { minDate, maxDate },
    };

    return (
      <Field
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={dateValidation}
        component={componentBuilder}
        initialValue={initialValue || null}
        minDate={minDate}
        maxDate={maxDate}
        {...props}
      />
    );
  };

  DateField.propTypes = fieldPropTypes;

  return DateField;
}
